<script>
import VTextarea from 'vuetify/lib/components/VTextarea'

export default {
  extends: VTextarea,
  mixins: [{
    props: {
      outlined: { default: true },
      // flat: { default: true },
      // backgroundColor: { default: '#f4f4f4' },
    },
  }],
}
</script>
