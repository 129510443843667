
import { StandardObject } from '@/store/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: true,
})

export default class CardVue extends Vue {
  @Prop({ type: String, default: 'secondary' })
  public color!: string
  @Prop({ type: [Number, String], default: 10 })
  public elevation!: number | string
  @Prop({ type: Boolean, default: false })
  public inline!: boolean
  @Prop({ type: Boolean, default: false })
  public fullWidth!: boolean
  @Prop({ type: [Number, String], default: 24 })
  public offset!: number | string
  @Prop({ type: String, default: undefined })
  public title!: string
  @Prop({ type: String, default: undefined })
  public text!: string

  private get hasOffset(): any {
    return this.$slots.header ||
      this.$slots.offset ||
      this.title ||
      this.text
  }

  private get styles(): StandardObject {
    if (!this.hasOffset) { return {} }

    let offsetVal: string | number = this.offset

    if (typeof offsetVal === 'string') {
      offsetVal = Number(offsetVal)
    }

    return {
      marginBottom: `${offsetVal}px`,
      marginTop: `${offsetVal * 2}px`,
    }
  }
}
