
import { Component, Vue } from 'vue-property-decorator'

@Component({})

export default class JigDateTimeFieldsVue extends Vue {
  private datePicker: boolean = false
  private timePicker: boolean = false
  private dateString: string = ''
  private timeString: string = ''

  public $refs!: Vue['$refs'] & {
    timePicker: Vue | undefined,
  }

  protected created() {
    this.dateString = this.currentDateTime.substring(0, 10)
    this.timeString = this.currentDateTime.substring(11, 16)
    this.updateDateTime()
  }

  private get currentDateTime(): string {
    // As in Date.toISOString function timezone is alwasy zero UTC offset, we need to manually get timezone offset in order to get local time ISO string.
    // Date.now() returns value in milliseconds, getTimezoneOffset() returns value in minutes, hence multiplying 60000
    const minInMilsec = 60000

    return (new Date(Date.now() - (new Date()).getTimezoneOffset() * minInMilsec)).toISOString()
  }

  private get userLocalTimezone(): string {
    // toString() returns date time string in following formt:
    // "Tue Aug 03 2021 11:26:18 GMT+1000 (Australian Eastern Standard Time)"
    // Use substring to get timezone details.
    const rightNow = (new Date()).toString()

    return rightNow.substring(rightNow.indexOf('GMT'))
  }

  private updateDate() {
    this.datePicker = false
    this.updateDateTime()
  }

  private updateTime() {
    (this.$refs.timePicker as any).save(this.timeString)
    this.updateDateTime()
  }

  public updateDateTime() {
    // ISO string format: 2021-07-29T16:20:25.427Z
    const newIsoString = this.parseISOString()
    this.$emit('on-datetime-change', newIsoString)
  }

  private parseISOString() {
    // Please note when using toISOString(), timezone is alwasy zero UTC offset.
    const dateString = new Date(`${this.dateString} ${this.timeString}`)

    return dateString.toISOString()
  }
}
