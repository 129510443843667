
import { DataTableHeading } from '@/modules/library/types'
import { StandardObject } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { orderBy } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({})

export default class DataTableVue extends Vue {
  @Prop({ type: String, default: '' })
  public uniqueId!: string
  @Prop({ type: Array as any, default: () => [] })
  public dataRows!: any[]
  @Prop({ type: Object, default: () => ({}) })
  public dataHead!: StandardObject
  @Prop({ type: Array as any, default: () => [10, 50, 100] })
  public itemsPerPageOptions!: number[]
  @Prop({ type: Number, default: 0 })
  public itemsPerPage!: number
  @Prop({ type: Number, default: 1 })
  public currentPage!: number
  @Prop({ type: Number, default: 0 })
  public totalItemsNum!: number
  @Prop({ type: Number, default: 0 })
  public totalPages!: number
  @Prop({ type: Boolean, default: true })
  public enablePagination!: boolean
  @Prop({ type: String, default: '' })
  public sortDefault!: string
  @Prop({ type: Boolean || undefined, default: undefined })
  public dataSelectAll!: boolean | undefined
  @Prop({ type: Boolean || undefined, default: undefined })
  public dataSelect!: boolean | undefined
  @Prop({ type: String, default: '' })
  public dataSelectKey!: string
  @Prop({ type: Function, default: () => {} })
  public dataSelectDisableCheck!: Function
  @Prop({ type: Boolean, default: true })
  public frontEndPagination!: boolean

  private itemsPerPageNum: number = this.itemsPerPage !== 0 ? this.itemsPerPage : this.itemsPerPageOptions[0]
  private rowsSelected: any[] = []
  private rowsSelectedItems: any[] = []
  private selectAllRows: boolean = false

  @Watch('itemsPerPage', { immediate: true })
  private onItemsPerPageChanged(newVal: number) {
    this.itemsPerPageNum = newVal !== 0 ? newVal : this.itemsPerPageOptions[0]
  }

  private get startItemNumber(): number {
    return this.itemsPerPageNum === -1 ? 1 : (this.currentPage - 1) * this.itemsPerPageNum + 1
  }

  private get endItemNumber(): number {
    return this.itemsPerPageNum === -1 ? this.totalItemsVal : Math.min(this.currentPage * this.itemsPerPageNum, this.totalItemsVal)
  }

  private get paginationSummary(): string {
    return `${this.startItemNumber} - ${this.endItemNumber} / ${this.totalItemsVal}`
  }

  private get totalItemsVal(): number {
    return this.totalItemsNum === 0 ? this.dataRows.length : this.totalItemsNum
  }

  private get totalPagesVal(): number {
    return this.totalPages === 0 ? Math.ceil(this.dataRows.length / this.itemsPerPage) : this.totalPages
  }

  private get dataItems(): any[] {
    return this.dataRows.length < this.startItemNumber ? this.dataRows : this.dataRows.slice(this.startItemNumber - 1, this.endItemNumber)
  }

  private get getQueryParams() {
    let queryOptions: { [key: string]: any } = {}

    if (this.$route.query) {
      queryOptions = {
        ...this.$route.query
      }
    }
    queryOptions.page = `${this.currentPage}`

    return queryOptions
  }

  private onItemsPerPageChange() {
    if (this.itemsPerPage * this.currentPage > this.dataRows.length) {
      this.currentPage = 1
    }

    this.$emit('on-items-per-page-change', this.itemsPerPageNum)
  }

  private goToPrevPage() {
    if (this.currentPage === 1) {
      return
    }

    this.currentPage -= 1
    if (!this.frontEndPagination) {
      this.unselectAllItems()

      if (!AppHelper.isDeepEqual(this.$route.query, this.getQueryParams)) {
        this.$router.replace({ query: this.getQueryParams })
      }
    }
    this.$emit('on-go-to-prev-page', this.currentPage)
  }

  private goToNextPage() {
    if (this.currentPage === this.totalPagesVal) {
      return
    }

    this.currentPage += 1
    if (!this.frontEndPagination) {
      this.unselectAllItems()

      if (!AppHelper.isDeepEqual(this.$route.query, this.getQueryParams)) {
        this.$router.replace({ query: this.getQueryParams })
      }
    }
    this.$emit('on-go-to-next-page', this.currentPage)
  }

  private sortData(heading: DataTableHeading<true>) {
    this.dataRows = orderBy(this.dataRows, heading.sortFunc || this.sortDefault, heading.isAsc ? 'asc' : 'desc')

    if (!this.selectAllRows) {
      this.unselectAllItems()
    }
  }

  private theadEvents(heading: DataTableHeading<true | false>, id: string) {
    const events: any = {}
    let sorted = false

    if (heading.sort) {
      events.click = () => {
        if (this.sortDefault === id) {
          heading.isAsc = !heading.isAsc
        } else {
          this.sortDefault = id
        }

        if (this.frontEndPagination) {
          this.sortData(heading)
          sorted = true
        }

        this.$emit('on-sorting', {
          isSortingAsc: heading.isAsc,
          sortId: id,
          sorted,
        })
      }
    }

    return events
  }

  private onItemSelectChange() {
    this.rowsSelectedItems = this.dataItems.filter((item: any) => this.rowsSelected.includes(item[this.dataSelectKey]))

    this.$emit('on-item-select-change', {
      rowsSelected: this.rowsSelected,
      rowsSelectedItems: this.rowsSelectedItems,
    })
  }

  private selectAllRowsChanged() {
    if (this.selectAllRows) {
      for (const item of this.dataRows) {
        if (!this.dataSelectDisableCheck(item) && !this.rowsSelected.includes(item[this.dataSelectKey])) {
          this.rowsSelected.push(item[this.dataSelectKey])
          this.rowsSelectedItems.push(item)
        }
      }
    } else {
      this.rowsSelected = []
      this.rowsSelectedItems = []
    }

    this.$emit('on-item-select-all-change', {
      selectAllRows: this.selectAllRows,
      rowsSelected: this.rowsSelected,
      rowsSelectedItems: this.rowsSelectedItems,
    })
  }

  private unselectAllItems() {
    this.selectAllRows = false
    this.rowsSelected = []
    this.rowsSelectedItems = []
  }
}
