<script>
import VTextField from 'vuetify/lib/components/VTextField/VTextField'

export default {
  extends: VTextField,
  mixins: [{
    props: {
      outlined: { default: true },
      // flat: { default: true },
      // backgroundColor: { default: '#f4f4f4' },
    },
  }],
}
</script>
