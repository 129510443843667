
import { StandardObject } from '@/store/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: true,
})

export default class ChartCardVue extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  public data!: StandardObject
  @Prop({ type: Array, default: () => ([]) })
  public eventHandlers!: any[]
  @Prop({ type: Object, default: () => ({}) })
  public options!: StandardObject
  @Prop({ type: String, default: undefined })
  public ratio!: string | undefined
  @Prop({ type: Array, default: () => ([]) })
  public responsiveOptions!: any[]
  @Prop({ type: String, required: true, validator: (v: any) => ['Bar', 'Line', 'Pie'].includes(v) })
  public type!: string
}
